<template>
    <div class="container-fluid">
        <base-table :ref="table" :filters="filters" :crudName="crudName" :newText="newText" :table="table"
            :endPoint="endPoint" :enableAdd="false" :enableEdit="true" :enableDelete="true"
            :relationships="relationships" :extras="extras" :columns="columns" :enableExtract="true" :orders="orders"
            :styleHeader="'border-bottom: 5px solid #6d57ff'" @danfe="danfe"></base-table>
    </div>
</template>

<script>
    import BaseTable from "@/components/Layouts/BaseTable.vue";

    export default {
        data() {
            return {
                extras: [],
                crudName: "Embalagens",
                orders: [
                    {
                        name: "",
                        column: "",
                    },
                    {
                        name: "Código",
                        column: "D2_COD",
                    },
                    {
                        name: "Filial",
                        column: "D2_FILIAL",
                    },
                    {
                        name: "Produto",
                        column: "B1_DESC",
                    },
                ],
                filters: [
                    {
                        name: "D2_COD",
                        label: "Código",
                        value: "",
                        type: "text",
                        applied: false,
                        class: "form-control",
                        columnSize: "col-3",
                    },
                    {
                        name: "DATE_DE",
                        label: "De",
                        value: "",
                        type: "date",
                        applied: false,
                        class: "form-control",
                        columnSize: "col-3",
                    },
                    {
                        name: "DATE_ATE",
                        label: "Até",
                        value: "",
                        type: "date",
                        applied: false,
                        class: "form-control",
                        columnSize: "col-3",
                    },
                ],
                newText: "",
                table: "invoice-list",
                endPoint: "invoices/packagingList/",
                relationships: [],
                columns: [
                    {
                        reportColumn: { COLUNA: 'NOTA FISCAL', CAMPO: 'D2_DOC' },
                        label: "Nota Fiscal",
                        type: "",
                        callbackFunction: "danfe",
                        class: "btn btn-info btn-sm",
                        labelCallback: function (row) {
                            return row.D2_DOC;
                        },
                        condition: function (row) {
                            if (row) {
                                return row.D2_DOC.trim() && row.C5_LOJAENT.trim() ? false : true;
                            }
                            return false;
                        },
                        conditionShow: function (row) {
                            if (row) {
                                return row.D2_DOC.trim() == "" ? false : true;
                            }
                            return true;
                        },
                    },
                    {
                        reportColumn: { COLUNA: 'OPERACAO', CAMPO: 'D2_CF' },
                        label: "Operação",
                        field: "D2_CF",
                    },
                    {
                        reportColumn: { COLUNA: 'DESCRICAO', CAMPO: 'X5_DESCRI' },
                        label: "Descrição",
                        field: "X5_DESCRI",
                    },
                    {
                        reportColumn: { COLUNA: 'FILIAL', CAMPO: 'D2_FILIAL' },
                        label: "Filial",
                        field: "D2_FILIAL",
                    },
                    {
                        reportColumn: { COLUNA: 'LOJA', CAMPO: 'D2_LOJA' },
                        label: "Loja",
                        field: "D2_LOJA",
                    },
                    {
                        reportColumn: { COLUNA: 'LOJA ENTREGA', CAMPO: 'C5_LOJAENT' },
                        label: "Loja Entrega",
                        field: "C5_LOJAENT",
                    },
                    {
                        reportColumn: { COLUNA: 'MUNICIPIO', CAMPO: 'A1_MUN' },
                        label: "Município",
                        field: "A1_MUN",
                    },
                    {
                        reportColumn: { COLUNA: 'CODIGO', CAMPO: 'D2_COD' },
                        label: "Código",
                        field: "D2_COD",
                    },
                    {
                        reportColumn: { COLUNA: 'PRODUTO', CAMPO: 'B1_DESC' },
                        label: "Descricão Produto",
                        field: "B1_DESC",
                    },
                    {
                        reportColumn: { COLUNA: 'EMISSAO', CAMPO: 'D2_EMISSAO' },
                        label: "Emissão",
                        field: "D2_EMISSAO",
                    },
                    {
                        reportColumn: { COLUNA: 'COND. PAGAMENTO', CAMPO: 'E4_DESCRI' },
                        label: "Cond. Pagamento",
                        field: "E4_DESCRI",
                    },
                    {
                        reportColumn: { COLUNA: 'QTD ENVIADA', CAMPO: 'D2_QUANT' },
                        label: "Qtd Enviada",
                        field: "D2_QUANT",
                    },
                    {
                        reportColumn: { COLUNA: 'QTD DEVOLVIDA', CAMPO: 'D1_QUANT' },
                        label: "Qtd Devolvida",
                        field: "D1_QUANT",
                    },
                    {
                        reportColumn: { COLUNA: 'QTD SALDO', CAMPO: 'VAZIO' },
                        label: "Qtd Saldo",
                        callback: function (row) {
                            if (row) {
                                var D2_QUANT = row.D2_QUANT;
                                var D1_QUANT = row.D1_QUANT;

                                return Number(D2_QUANT) - Number(D1_QUANT);
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        reportColumn: { COLUNA: 'VLR UNITARIO', CAMPO: 'D2_PRCVEN' },
                        label: "Vlr Unitário",
                        callback: function (row) {
                            if (row) {
                                var D2_PRCVEN = row.D2_PRCVEN;

                                return Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(D2_PRCVEN);
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        reportColumn: { COLUNA: 'VLR TOTAL', CAMPO: 'D2_TOTAL' },
                        label: "Vlr Total",
                        callback: function (row) {
                            if (row) {
                                var D2_TOTAL = row.D2_TOTAL;

                                return Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(D2_TOTAL);
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        reportColumn: { COLUNA: 'VLR DEVOLVIDO', CAMPO: 'VAZIO' },
                        label: "Vlr Devolvido",
                        callback: function (row) {
                            if (row) {
                                var D1_QUANT = row.D1_QUANT;
                                var D2_PRCVEN = row.D2_PRCVEN;

                                return Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(Number(D1_QUANT) * Number(D2_PRCVEN));
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        reportColumn: { COLUNA: 'VLR SALDO DOCUMENTO', CAMPO: 'VAZIO' },
                        label: "Vlr Saldo Documento",
                        callback: function (row) {
                            if (row) {
                                var D2_TOTAL = row.D2_TOTAL;
                                var D1_TOTAL = row.D1_TOTAL;

                                return Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(Number(D2_TOTAL) - Number(D1_TOTAL));
                            } else {
                                return "";
                            }
                        },
                    },
                ],
            };
        },
        computed: {},
        methods: {
            danfe(data) {
                const self = this;

                if (data.D2_DOC.trim() && data.C5_LOJAENT.trim()) {
                    data = btoa(
                        JSON.stringify({
                            nota_fiscal: data.D2_DOC.trim(),
                            loja_ent: data.C5_LOJAENT.trim(),
                            contrato: 0,
                            filial: data.D2_FILIAL.trim(),
                        })
                    );
                    this.$router.push("/admin/invoice/danfe/" + data);
                } else {
                    alert("DANFE não disponível para esse título");
                }
            },
            encode(string) {
                return btoa(string);
            },
            brNumber(number) {
                return new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }).format(number);
            },
        },
        mounted() {
        },
        components: {
            BaseTable,
        },
    };
</script>