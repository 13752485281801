<template>
    <div class="container-fluid">
        <div class="row" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Usuário</h5>
                    </div>
                    <div class="card-body">
                        <div class="">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home"
                                        role="tab" aria-controls="home" aria-selected="true">Cadastro</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab"
                                        aria-controls="profile" aria-selected="false">Acessos</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="contact-tab" data-bs-toggle="tab" href="#contact" role="tab"
                                        aria-controls="contact" aria-selected="false">Clientes e Filiais</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="security-tab" data-bs-toggle="tab" href="#security" role="tab"
                                        aria-controls="security" aria-selected="false">Segurança</a>
                                </li>
                            </ul>

                            <div class="tab-content mt-3" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Nome</label>
                                                <input type="text" class="form-control" v-model="user.name" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>E-mail</label>
                                                <input type="text" class="form-control" v-model="user.email"
                                                    autocomplete="new-password" />
                                            </div>
                                        </div>
                                        <div class="col-4" v-if="$store.getters.user.type_id == 1 ||
                                            $store.getters.user.type_id == 4
                                            ">
                                            <div class="form-group">
                                                <label>Nível de Acesso</label>
                                                <select class="form-select" v-model="user.type_id">
                                                    <option value="4">Administrador Master</option>
                                                    <option value="1">Administrador</option>
                                                    <option value="2">Cliente</option>
                                                    <option value="3">Usuário Interno</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>CPF</label>
                                                <input type="text" v-maska="'###.###.###-##'" class="form-control"
                                                    v-model="user.cpf" />
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Cargo</label>
                                                <input type="text" class="form-control" v-model="user.office" />
                                            </div>
                                        </div>

                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Telefone</label>
                                                <input type="text" placeholder="(__) ____-____"
                                                    v-maska="['(##) ####-####', '(##) #####-####']" class="form-control"
                                                    v-model="user.phone" />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    

                                    <div class="row">

                                        <div class="col">
                                            <a data-toggle="collapse" href="#collapseFilter"
                                            aria-expanded="false" aria-controls="collapseFilter"
                                            class="btn-filter"></a>
                                        </div>

                                        <div class="col-12 mt-12 text-center">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="selectAll"
                                                    @click="selectAll" />
                                                <label style="font-weight: 900; font-size: 16px"
                                                    class="custom-control-label" for="selectAll">Selecionar
                                                    todos</label>
                                            </div>
                                        </div>

                                        <div class="col-md-4 mt-4">

                                            <div class="mb-3 mt-4">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="contracts"
                                                        value="2" @click="verifySelect" />
                                                    <label class="custom-control-label"
                                                        for="contracts">Contratos</label>
                                                </div>
                                            </div>

                                            <div class="mb-3 mt-4">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="requests"
                                                        value="9" @click="showSub('requests')" />
                                                    <label class="custom-control-label" for="requests">Pedidos</label>
                                                </div>
                                                <div class="pl-4" id="requestsSub" style="display: none">
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsNew" value="10" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsNew">
                                                            Novo Pedido
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsInvoice" value="11" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsInvoice">
                                                            Pedido a faturar
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsOnRoute" value="12" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsOnRoute">
                                                            Pedidos em rota
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsDelivery" value="13" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsDelivery">
                                                            Pedidos entregues
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsFinished" value="14" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsFinished">
                                                            Pedidos realizados
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="requestsDraft" value="15" @click="verifySelect" />
                                                        <label class="custom-control-label" for="requestsDraft">
                                                            Pedidos em rascunho
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>

                                        <div class="col-md-4 mt-4">

                                            <div class="mb-3 mt-4">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="financial"
                                                        value="3" @click="showSub('financial')" />
                                                    <label class="custom-control-label my-auto"
                                                        for="financial">Financeiro</label>
                                                </div>
                                                <div class="pl-4" id="financialSub" style="display: none">
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="financialSearch" value="4" @click="verifySelect" />
                                                        <label class="custom-control-label" for="financialSearch">
                                                            Consulta de Títulos
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="financialDash" value="5" @click="verifySelect" />
                                                        <label class="custom-control-label" for="financialDash">
                                                            Dashboard Financeiro
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                           
                                            <div class="mb-3 mt-4">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="documents"
                                                        value="20" @click="showSub('documents')" />
                                                    <label class="custom-control-label my-auto"
                                                        for="documents">Documentos</label>
                                                </div>
                                                <div class="pl-4" id="documentsSub" style="display: none">
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="documentsFds" value="21" @click="verifySelect" />
                                                        <label class="custom-control-label" for="documentsFds">
                                                            FDS
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="documentsEmergencyForm" value="22"
                                                            @click="verifySelect" />
                                                        <label class="custom-control-label"
                                                            for="documentsEmergencyForm">
                                                            Ficha de emergência
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="documentsWinner" value="29" @click="verifySelect" />
                                                        <label class="custom-control-label" for="documentsWinner">
                                                            Certidões
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4 mt-4">
                                           
                                            <div class="mb-3 mt-4">
                                                <div class="custom-control custom-switch mt-3" v-if="isClient">
                                                    <input type="checkbox" class="custom-control-input" id="adm"
                                                        value="23" @click="showSub('adm')" />
                                                    <label class="custom-control-label my-auto"
                                                        for="adm">Administrativo</label>
                                                </div>
                                                <div class="pl-4" id="admSub" style="display: none">
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="admUsers" value="24" @click="verifySelect" />
                                                        <label class="custom-control-label" for="admUsers">
                                                            Usuários
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="admLogTime" value="25" @click="verifySelect" />
                                                        <label class="custom-control-label" for="admLogTime">
                                                            Tempo de logs
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="admLogAccess" value="26" @click="verifySelect" />
                                                        <label class="custom-control-label" for="admLogAccess">
                                                            Log de acesso
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="admSettings" value="27" @click="verifySelect" />
                                                        <label class="custom-control-label" for="admSettings">
                                                            Configurações
                                                        </label>
                                                    </div>
                                                    <div class="custom-control custom-switch mt-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="admReports" value="28" @click="verifySelect" />
                                                        <label class="custom-control-label" for="admReports">
                                                            Relatórios
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div v-if="isAmbientaly" class="mb-3 mt-4">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="packaging"
                                                        value="19" @click="verifySelect" />
                                                    <label class="custom-control-label my-auto"
                                                        for="packaging">Embalagens</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                    
                                    <div class="row">
                                        <div class="col">

                                            <b>Cliente Ativo</b>: {{selectedCustomer.code ? (selectedCustomer.code + ' - ' + selectedCustomer.name) : '...'}}
                                            <br>
                                            <b>Lojas</b>: {{ getLojasTexto()}}
                                        </div>
                                    </div>

                                   
                                    
                                    <div class="row">

                                        <div class="col-12 mt-3">
                                            <label for="">Adicionar Cliente</label>
                                            <v-select :options="customerList" label="name" v-model="multipleCustomer">
                                            </v-select>
    
                                            <button @click="addCustomer" class="btn btn-primary mt-2">Adicionar</button>
                                            <hr>
                                            <h4>Clientes vínculados</h4>
                                            <ul>
                                                <li v-for="(customer, index) in userRelatedCustomers" :key="index"
                                                    class="customer-item">
                                                    <div class="row customer-container">
                                                        
                                                        <div class="col"> 
                                                            <button class="btn btn-danger me-2" @click="deleteCustomer(index)">
                                                                <i class="fa fa-trash"></i>
                                                            </button>                                                           
                                                            {{ customer.name }} 
                                                        </div>
                                                        <div class="col">
                                                            <v-select class="form-select" v-model="selectedStores[index].stores"
                                                                multiple :filterable="false" label="store_id"
                                                                :options="stores[index]" @input="getStores(customer, index)">
                                                            </v-select>
                                                            
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="security" role="tabpanel"
                                aria-labelledby="security-tab">                                    
                                    <div class="row">
                                        <div class="col-md-12" v-if="userId">
                                            <div class="alert alert-primary" role="alert">
                                                <strong>Atenção!</strong> Ao clicar no botão
                                                <strong>Gerar Nova Senha </strong>, será enviado um link para
                                                alteração de senha no e-mail cadastrado aqui.
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="userId">
                                            <button class="btn btn-primary" @click="sendNewPassword">
                                                Gerar Nova Senha
                                            </button>
                                        </div>
                                        <div class="col-md-12" v-else>
                                            <div class="alert alert-info">
                                                Para novos usuários é enviado um link para
                                                criação da senha.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
              
                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-primary float-right" @click="save">
                                    Salvar
                                </button>
                                <router-link :to="'/admin/user-list'" class="btn btn-danger float-right mr-3">Voltar
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="loading">
            <div class="card-body text-center">
                Atualizando informações do cliente...
                <span class="fa fa-spinner fa-spin fa-5x"></span>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import env from '@/env'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            isClient: true,
            loading: false,
            locked: true,
            isAmbientaly: env.isAmbientaly,
            customers: [],
            stores: [],
            selectedCustomer: {},
            selectedStores: [],            
            multipleCustomer: null,            
            userId: this.$route.params.id,
            user: {
                name: "",
                cpf: "",
                phone: "",
                email: "",
                password: "",
                office: "",
                customer_id: null,
                stores: [],
                type_id: "", 
                access_contracts: false,
                access_invoices: false,
                access_requests: false,
                access_logistics: false,
                access_logs: true,
                access_tickets_message: true,
                access: []
            },
        };
    },
    computed: {
        customerList() {
            return this.customers;
        },
        userRelatedCustomers(){
            return this.selectedStores.filter(item => {
                return !item.deleted_at;
            })
        }
    },
    methods: {
        deleteCustomer(index){
            this.userRelatedCustomers[index].deleted_at = true
        },
        getLojasTexto(){

            if(this.user.stores.length == 0){
                return 'Acesso a todas as lojas';
            }

            let lojas = ''

            this.user.stores.forEach( item => {
                lojas+= item.store_id + ', '
            })

            return lojas
        },
        addCustomer() {
            if (this.multipleCustomer) {
             
                this.selectedStores.push({
                    ...this.multipleCustomer,                    
                    stores: [],
                });

                // Fetch stores for the selected customer
                let index = this.selectedStores.length - 1
                this.getStores(this.multipleCustomer, index);

                // Clear the selected customer after adding
                this.multipleCustomer = null;
            }
        },
        selectAll() {
            const selected = $('#selectAll').prop('checked');
            if (selected === true) {
                console.log(this.isClient);
                this.isClient == 2 ? false : true;
                if (this.isClient === true) {
                    $('#admSub, #deliverySub, #documentsSub, #financialSub, #quotesSub, #requestsSub').show(300);
                }else{
                    $('#deliverySub, #documentsSub, #financialSub, #quotesSub, #requestsSub').show(300);
                }

            } else {
                $('#admSub, #deliverySub, #documentsSub, #financialSub, #quotesSub, #requestsSub').hide(300);
            }
            $('input[type="checkbox"]').prop('checked', selected)
        },
        verifySelect() {
            const checked = $('input[type="checkbox"]:not(#selectAll):checked');
            const total = $('input[type="checkbox"]:not(#selectAll)');
            const verify = checked.length === total.length;

            $('#selectAll').prop('checked', verify);
        },
        showSub(id) {
            const selected = $('#' + id).prop('checked');

            $('#' + id + 'Sub input[type="checkbox"]').prop('checked', selected)
            if (selected === true) {
                $('#' + id + 'Sub').show(300);
            } else {
                $('#' + id + 'Sub').hide(300);
            }
            this.verifySelect();
        },
        searchCustomers(search, loading) {
            let self = this;
            const api = self.$store.state.api + "customers?paginated=false";

            axios
                .get(api)
                .then((response) => {
                 
                    self.customers = response.data.data;                    
                })
                .catch((error) => {
                    // vm.$toast.error(error.response.data.message);
                });
        },
        search: _.debounce((loading, search, vm) => {
            const api =
                vm.$store.state.api + "customers?name=" + search + "&A1_MSBLQL=2";

            axios
                .get(api)
                .then((response) => {
                    vm.customers = response.data.data;
                })
                .then(() => {
                    loading(false);
                })
                .catch((error) => {
                    vm.$toast.error(error.response.data.message);
                });
        }, 350),
        get_data(id) {
            const self = this;

            const api = this.$store.state.api + "users/" + id + '?with[]=customer_control.customer&with[]=customer_control.customer_controls_stores.store';

            axios
                .get(api)
                .then((response) => {
                    response.data.data[0].access_contracts =
                        response.data.data[0].access_contracts == 1 ? true : false;
                    response.data.data[0].access_invoices =
                        response.data.data[0].access_invoices == 1 ? true : false;
                    response.data.data[0].access_requests =
                        response.data.data[0].access_requests == 1 ? true : false;
                    response.data.data[0].access_logistics =
                        response.data.data[0].access_logistics == 1 ? true : false;
                    response.data.data[0].access_logs =
                        response.data.data[0].access_logs == 1 ? true : false;
                    response.data.data[0].access_tickets_message =
                        response.data.data[0].access_tickets_message == 1 ? true : false;
                    self.user = response.data.data[0];
                    self.user.stores = [];
                    self.user.access = response.data.data[0].access;
                    self.user.access.forEach((element) => {
                        $('.custom-control-input[value="' + element + '"]').prop('checked', true);
                    })

                    let cont = 0;
                    response.data.data[0].customer_control.forEach(item => {

                        item.customer_controls_stores.forEach(store => {
                            store.store_id = store.store.store_id
                            store.id       = store.store.id
                        })

                        self.selectedStores.push({
                            ...item.customer,
                            stores: item.customer_controls_stores
                        })

                        self.getStores(item.customer, cont)
                        cont++;
                    })

                    if (self.user.access.indexOf(3) > -1) $('#financialSub').show(300);
                    if (self.user.access.indexOf(6) > -1) $('#quotesSub').show(300);
                    if (self.user.access.indexOf(9) > -1) $('#requestsSub').show(300);
                    if (self.user.access.indexOf(16) > -1) $('#deliverySub').show(300);
                    if (self.user.access.indexOf(20) > -1) $('#documentsSub').show(300);
                    if (self.user.access.indexOf(23) > -1) $('#admSub').show(300);
                    this.verifySelect();
                })
                .then(() => {
                    self.getSelectedCustomer();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        save() {
            const self = this;
            // this.loading = true;
            let api = self.$store.state.api + "users/";
            self.user.access = [];

            if (self.user.id) {
                api += self.user.id;
            }

            const checked = $('input[type="checkbox"]:not(#selectAll, #readonlyStores):checked');
            if (checked.length === 0) {
                self.$toast.error('Você precisa definir os acessos do usuário');
                return;
            }

            for (let i = 0; i < checked.length; i++) {
                self.user.access.push(checked[i].value);
            }

            const fd = new FormData();
            fd.append("name", self.user.name);
            // fd.append("customer_id", self.selectedCustomer.id); // Certifique-se de que o ID do cliente selecionado seja enviado
            fd.append("type_id", self.user.type_id);
            fd.append("email", self.user.email);
            fd.append("access", self.user.access);
            fd.append('multiple_customer',JSON.stringify(self.selectedStores));
          
            if (self.user.cpf) fd.append("cpf", self.user.cpf);
            if (self.user.phone) fd.append("phone", self.user.phone);
            if (self.user.office) fd.append("office", self.user.office);

            if (self.user.password) {
                fd.append("password", self.user.password);
            }
            if (self.user.id) {
                fd.append("id", self.user.id);
                fd.append("_method", "PUT");
            }

            if (self.user.stores.length > 0) {
                self.user.stores.forEach((selecteds) => {
                    fd.append("stores[]", selecteds.id);
                });
            }

            fd.append("access_contracts", self.user.access_contracts ? 1 : 0);
            fd.append("access_invoices", self.user.access_invoices ? 1 : 0);
            fd.append("access_requests", self.user.access_requests ? 1 : 0);
            fd.append("access_logistics", self.user.access_logistics ? 1 : 0);
            fd.append("access_logs", self.user.access_logs ? 1 : 0);
            fd.append(
                "access_tickets_message",
                self.user.access_tickets_message ? 1 : 0
            );
            
            axios
                .post(api, fd)
                .then((response) => {
                    self.$toast.success("Registro salvo com sucesso");
                    self.$router.push("/admin/user-list");
                    // self.loading = false;
                })
                .catch((error) => {
                    // self.loading = false;
                    if (error.response.data.message) {
                        self.$toast.error(error.response.data.message);
                    } else if (typeof error.response.data === "object") {
                        for (let item in error.response.data) {
                            self.$toast.error(error.response.data[item][0]);
                        }
                    }
                   
                    
                });
            
           
        },
        getSelectedCustomer() {
            let self = this;
            const api =
                self.$store.state.api + "customers?id=" + this.user.customer_id;

            axios
                .get(api)
                .then((response) => {
                    self.selectedCustomer = response.data.data[0];
                    // self.multipleCustomer = response.data.data[0];
                    // self.getStores();
                    self.getSelectedStoreCustomer();
                })
                .catch((error) => {
                    // vm.$toast.error(error.response.data.message);
                });
        },
        sendNewPassword() {
            let self = this;
            const api = self.$store.state.api + "user/new-password";

            axios
                .post(api, { id: self.user.id })
                .then((response) => {
                    this.$toast.success(
                        "OK! Uma nova senha foi enviada para o e-mail: " + self.user.email
                    );
                })
                .catch((error) => {
                    // vm.$toast.error(error.response.data.message);
                });
        },
        getSelectedStoreCustomer() {
            let self = this;
            const api =
                self.$store.state.api +
                "customer_user_stores?with[]=customer_store&user_id=" +
                this.user.id;

            axios
                .get(api)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        self.user.stores.push(element.customer_store);
                    });

                    this.locked = !self.user.stores.length > 0;
                })
                .catch((error) => {
                    // vm.$toast.error(error.response.data.message);
                });
        },
        getStores(customer, index) {
            let self = this;
            const api =
                self.$store.state.api +
                "customer_stores?paginated=false&orderByAsc=store_id&customer_id=" +
                customer.code;

            axios
                .get(api)
                .then((response) => {
                    self.stores[index] = response.data.data;
                })
                .catch((error) => {
                    // Handle the error here
                });
        },
    },
    watch: {
        'user.type_id': function (newVal) {

            this.isClient = newVal == 2 ? false : true;
            console.log(this.isClient);


        },
    },
    mounted() {
        if (this.userId) {
            this.get_data(this.userId);
        }
        this.searchCustomers();
    },
};
</script>
<style scoped>
.customer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-name {
    flex: 1;
    text-align: left;
}

.store-select {
    flex: 1;
    text-align: right;
}
</style>