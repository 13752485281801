<template>
  <div class="container-fluid">
    <base-table :ref="table" :filters="filters" :crudName="crudName" :newText="newText" :table="table"
      :endPoint="endPoint" :enableAdd="false" :enableEdit="true" :enableDelete="true" :enableExtract="true"
      :layoutNames="layoutNames" :enableSaveColumns="true" :relationships="relationships" :extras="extras"
      :orders="orders" :columns="columns" @fispq="fispq" @report="report" @tracking="tracking"
      :styleHeader="'border-bottom: 5px solid #51A6F5'"></base-table>
  </div>
</template>

<script>
  import BaseTable from "@/components/Layouts/BaseTable.vue";
  import env from "../../env.js";
  export default {
    data() {
      return {
        loaded: false,
        extras: [],
        orders: [
          {
            name: "",
            column: "",
          },
          {
            name: "Nº do Pedido",
            column: "C5_NUM",
          },
          {
            name: "Pedido Cliente",
            column: "C6_NUMPCOM",
          },
          {
            name: "Local de Entrega",
            column: "A1_ZAPLENT",
          },
          {
            name: "Previsão de Entrega",
            column: "C6_ENTREG",
            selected: true,
          },
        ],
        filters: [
          {
            id: "invoiceType",
            type: "hidden",
            name: "status",
            label: "Pendentes",
            value: "unbilled",
            checked: true,
          },
          {
            name: "C5_NUM",
            label: "N° do Pedido",
            value: "",
            type: "text",
            applied: false,
            class: "form-control",
            columnSize: "col-3",
          },
          {
            name: "C6_NUMPCOM",
            label: "Pedido do Cliente",
            value: "",
            type: "text",
            applied: false,
            class: "form-control",
            columnSize: "col-3",
          },
          {
            name: "B1_DESC",
            product_column: "B1_ESPECIE",
            label: "Produto",
            value: "",
            type: "product",
            applied: false,
            class: "form-control",
            columnSize: "col-3 mt-2",
          },
          {
            name: "C6_ENTREG",
            label: "Previsão de Entrega",
            value: "",
            type: "date",
            applied: false,
            class: "form-control",
            columnSize: "col-3",
          },
          {
            name: "A1_ZAPLENT",
            label: "Local de Entrega",
            value: "",
            type: "text",
            applied: false,
            class: "form-control",
            columnSize: "col-3",
          },
          {
            name: "CN9_SITUAC",
            label: "Status",
            value: "",
            type: "select",
            options: {
              "": "",
              "01": "Cancelado",
              "02": "Elaboração",
              "03": "Emitido",
              "04": "Aprovado",
              "05": "Vigente",
              "06": "Paralisado",
              "07": "Solicitação Finalizada",
              "08": "Finalizado",
              "09": "Revisão",
              10: "Revisado",
              A: "Aprovado Revisão",
            },
            applied: false,
            class: "form-control",
            columnSize: "col-3",
          },
          // {
          //     column: "text",
          //     label: "Ativos/Finalizados",
          //     value: "",
          //     type: "text",
          //     applied: false,
          // },
        ],
        crudName: "Pedidos a Faturar",
        newText: "",
        table: "order-list-unbilled",
        endPoint: "orders/",
        relationships: [],
        columns: [
          // {
          //   label: "FISPQ",
          //   type: "button",
          //   callbackFunction: "fispq",
          //   class: "btn btn-info btn-sm",
          //   condition: function (row) {
          //     if (true) {
          //       return false;
          //     } else {
          //       return false;
          //     }
          //   },
          //   conditionShow: function (row) {
          //     return true;
          //   },
          // },
          // {
          //   label: "Laudo",
          //   type: "button",
          //   callbackFunction: "report",
          //   class: "btn btn-info btn-sm",
          //   condition: function (row) {
          //     if (row.F2_FILIAL == "0650002") {
          //       if (row.C5_NOTA.trim() == "") {
          //         return true;
          //       } else {
          //         return false;
          //       }
          //     } else {
          //       return false;
          //     }
          //   },
          //   conditionShow: function (row) {
          //     if (row.F2_FILIAL == "0650002") {
          //       if (row.C5_NOTA.trim() == "") {
          //         return false;
          //       } else {
          //         return true;
          //       }
          //     } else {
          //       return false;
          //     }
          //   },
          // },
          {
            reportColumn: { COLUNA: 'LOCAL DE ENTREGA', CAMPO: 'A1_ZAPLENT' },
            label: "Local de Entrega",
            callback: function (row) {
              var field = row.A1_ZAPLENT;
              if (field.trim() == "") {
                return "";
              }
              return field;
            },
          },
          {
            reportColumn: { COLUNA: 'MUNICIPIO DE ENTREGA', CAMPO: 'CC2_MUN' },
            label: "Município de Entrega",
            callback: function (row) {
              var field = row.CC2_MUN;
              if (field.trim() == "") {
                return "";
              }
              return field;
            },
          },
          {
            reportColumn: { COLUNA: 'PRODUTO', CAMPO: 'B1_DESC' },
            label: "Produto",
            field: "B1_ESPECIE",
          },
          {
            reportColumn: { COLUNA: 'QUANTIDADE SOLICITADA (KG)', CAMPO: 'VAZIO' },
            label: "Quantidade Solicitada (Kg)",
            callback: function (row) {
              var C6_QTDVEN = row.C6_QTDVEN;
              C6_QTDVEN = C6_QTDVEN.split(".").join("");
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(C6_QTDVEN);
            },
          },
          {
            reportColumn: { COLUNA: 'UNIDADE MEDIDA', CAMPO: 'VAZIO' },
            label: "Unidade medida",
            callback: function (row) {
              var C6_XUNFAT = row.C6_XUNFAT;

              var C6_SEGUM = row.C6_SEGUM;
              var C6_UM = row.C6_UM;

              if (C6_XUNFAT.trim() != "") {
                return C6_SEGUM;
              } else {
                return C6_UM;
              }
            },
          },
          {
            reportColumn: { COLUNA: 'PREVISAO DE ENTREGA', CAMPO: 'C6_ENTREG' },
            label: "Previsão de Entrega",
            field: "C6_ENTREG",
          },
          {
            reportColumn: { COLUNA: 'PEDIDO DO CLIENTE', CAMPO: 'C6_NUMPCOM' },
            label: "Pedido do Cliente",
            field: "C6_NUMPCOM",
          },
          {
            reportColumn: { COLUNA: 'FILIAL', CAMPO: 'M0_FILIAL' },
            label: "Filial",
            callback: function (row) {
              if (row) {
                var string = row.C5_FILIAL;
                string = string.substring(string.indexOf(" - ") + 2);
                return string;
              } else {
                return "";
              }
            },
          },
          {
            reportColumn: { COLUNA: 'NUM DO PEDIDO', CAMPO: 'C5_NUM' },
            label: "Nº do Pedido",
            field: "C5_NUM",
          },
        ],
      };
    },
    components: {
      BaseTable,
    },
    methods: {
      tracking(data) {
        data = btoa(
          JSON.stringify({
            placa: data.DA3_PLACA,
            placaCav: data.DAK_VEIC2,
            transportadora: data.A4_NOME,
            notafiscal: data.F2_DOC,
          })
        );

        this.$router.push("/admin/delivery/tracking/" + data);
      },
      fispq(data) {
        data = btoa(
          JSON.stringify({
            nome_produto: data.B1_DESC,
          })
        );
        this.$router.push("/admin/order/fispq/" + data);
      },
      report(data) {
        const obj = {
          filial: data.F2_FILIAL,
          doc: data.F2_DOC,
          serie: data.F2_SERIE
        }
        data = btoa(
          JSON.stringify({
            nota_fiscal: data.F2_DOC.trim(),
          })
        );
        this.$store.dispatch("setOrderReport", obj);
        this.$router.push("/admin/order/report/" + data);
      },
      encode(string) {
        return btoa(string);
      },
    },
  };
</script>