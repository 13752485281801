const env = {
    url: 'https://api.ambientaly.innovareti.com.br/',
    api: 'https://api.ambientaly.innovareti.com.br/api/v1/',
    applicationName: 'AMBIENTALY',
    applicationFooter: 'AMBIENTALY',
    chartBarBackgroundColor: '#3AA935',
    isAmbientaly: true
}

export default env
