<template>

    <div id="mainAPPDiv" data-theme-version="light" data-layout="vertical" data-nav-headerbg="color_1"
        data-headerbg="color_1" data-sidebar-style="full" data-sidebarbg="color_1" data-sidebar-position="fixed"
        data-header-position="fixed" data-container="wide" direction="ltr" data-primary="color_1">
        <div class="nav-header">
            <!--
                Retirado de novo pedido por pedido de Perri
                to="/admin/order-new"
            -->
            <!-- Alerta personalizado -->

            <div class="card" v-if="showAlert">
                <div class="custom-alert">
                    <div class="custom-alert-content">
                        <h2>Alerta</h2>
                        <p>A criação de pedidos via Portal está indisponível no momento.</p>
                        <button @click="handleAlertOk">OK</button>
                    </div>

                </div>

            </div>



            <router-link to="/" class="brand-logo">
                <img class="logo-full" src="../../assets/images/logo.png" />

                <img class="logo-mini" src="../../assets/images/favicon.png" />
            </router-link>

            <div class="nav-control">
                <div class="hamburger" @click="controlMenu()">
                    <span class="line"></span><span class="line"></span><span class="line"></span>
                </div>
            </div>
        </div>

        <header-bar @activateSpinner="activateSpinner"></header-bar>

        <div class="deznav">
            <div class="deznav-scroll">
                <ul class="metismenu" id="menu">
                    <li class="nav-label first"></li>
                    <li :class="{ 'active-menu mm-active': $route.path == '/admin' }">
                        <router-link to="/admin" class="ai-icon">
                            <i class="flaticon-381-home"></i>
                            <span class="nav-text">Página Inicial </span>
                        </router-link>
                    </li>
                    <!-- <li
                        v-if="$store.getters.user.access.indexOf(1) > -1"
                        :class="{ 'active-menu mm-active': $route.path == '/admin/kanban' }"
                    >
                        <router-link to="/admin/kanban" class="ai-icon">
                            <i class="flaticon-144-layout"></i>
                            <span class="nav-text">Resumo Geral </span>
                        </router-link>
                    </li>
                  <li
                      :class="{
                        'active-menu mm-active': $route.path == '/admin/financial-dash',
                      }"
                    >
                      <router-link to="/admin/financial-dash" class="ai-icon">
                        <i class="flaticon-144-layout"></i>
                        <span class="nav-text">Dashboard Fin </span>
                      </router-link>
                    </li> -->
                    <li v-if="$store.getters.user.access.indexOf(2) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/contract-list',
            }">
                        <router-link to="/admin/contract-list" class="ai-icon">
                            <img src="../../assets/images/icons/contratos.png" width="28"
                                style="margin-left: -5px; margin-right: 13px" />
                            <span class="nav-text">Contratos</span>
                        </router-link>
                    </li>
                    <li v-if="$store.getters.user.access.indexOf(3) > -1" :class="{
                'active-menu mm-active':
                    $route.path == '/admin/invoice-list' ||
                    $route.path == '/admin/financial-dash',
            }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenu">
                            <img src="../../assets/images/icons/financeiro.png" width="23"
                                style="margin-left: -3px; margin-right: 17px" />
                            <span class="nav-text">Financeiro</span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 45%; font-weight: 100; margin-right: -15px"
                                :icon="!menuFinanceiroOpened ? ['fa', 'plus'] : ['fa', 'minus']" />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuFinanceiro">
                            <li v-if="$store.getters.user.access.indexOf(4) > -1" class="mm-active">
                                <router-link to="/admin/invoice-list" class="mm-active">Consulta de Títulos
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(5) > -1">
                                <router-link to="/admin/financial-dash" class="mm-active">Dashboard Financeiro
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <!-- <li v-if="$store.getters.user.access.indexOf(6) > -1" :class="{
                            'active-menu mm-active':
                                $route.path == '/admin/licitation-list' ||
                                $route.path == '/admin/licitation-form',
                        }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenuCotacao">
                            <img src="../../assets/images/icons/cotacao.png" width="23"
                                style="margin-left: -3px; margin-right: 15px" />
                            <span class="nav-text">Cotações</span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 48%; font-weight: 100; margin-right: -15px"
                                :icon="!menuCotacaoOpened ? ['fa', 'plus'] : ['fa', 'minus']" />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuCotacoes">
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(7) > -1">
                                <router-link to="/admin/licitation-form" class="mm-active">Solicitar Cotação
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(8) > -1">
                                <router-link to="/admin/licitation-list" class="mm-active">Cotações Solicitadas
                                </router-link>
                            </li>
                        </ul>
                    </li> -->
                    <li v-if="$store.getters.user.access.indexOf(9) > -1" :class="{
                            'active-menu mm-active':
                                $route.path == '/admin/order-list' ||
                                $route.path == '/admin/order-new' ||
                                $route.path == '/admin/order-finished-list' ||
                                $route.path == '/admin/order-list-ni',
                        }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenuOrder">
                            <i class="flaticon-044-file"></i>
                            <span class="nav-text">Pedidos </span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 52%; font-weight: 100; margin-right: -15px"
                                :icon="!menuPedidosOpened ? ['fa', 'plus'] : ['fa', 'minus']" />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuPedidos">
                            <li v-if="$store.getters.user.access.indexOf(10) > -1">
                                <router-link to="/admin/order-new" class="mm-active" >Novo Pedido
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(11) > -1">
                                <router-link to="/admin/order-unbilled-list" class="mm-active">Pedidos a Faturar
                                </router-link>
                            </li>
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(12) > -1">
                                <router-link to="/admin/order-list" class="mm-active">Pedidos em Rota
                                </router-link>
                            </li>
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(13) > -1">
                                <router-link to="/admin/order-finished-list" class="mm-active">Pedidos Entregues
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(14) > -1">
                                <router-link to="/admin/order-list-ni" class="mm-active">Pedidos Realizados
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(15) > -1">
                                <router-link to="/admin/order-draft" class="mm-active">Pedidos em Rascunho
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <!-- <li v-if="$store.getters.user.access.indexOf(16) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/delivery-list',
            }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenuDelivery">
                            <img src="../../assets/images/icons/entregas.png" width="23"
                                style="margin-left: -3px; margin-right: 14px" />
                            <span class="nav-text">Entregas </span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 51%; font-weight: 100; margin-right: -11px"
                                :icon="!menuDeliveryOpened ? ['fa', 'plus'] : ['fa', 'minus']" />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuDelivery">
                            <li v-if="$store.getters.user.access.indexOf(17) > -1">
                                <router-link to="/admin/delivery-list" class="mm-active">Entregas em Andamento
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(18) > -1">
                                <router-link to="/admin/delivered-list" class="mm-active">Entregas Concluídas
                                </router-link>
                            </li>
                        </ul>
                    </li> -->
                    <li v-if="dotEnv.isAmbientaly && $store.getters.user.access.indexOf(19) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/packaging-list',
            }">
                        <router-link to="/admin/packaging-list" class="ai-icon">
                            <i class="flaticon-381-price-tag"></i>
                            <span class="nav-text">Embalagens </span>
                        </router-link>
                    </li>

                    <li v-if="$store.getters.user.access.indexOf(20) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/documents-list',
            }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenuDocuments">
                            <img src="../../assets/images/icons/documentos.png" width="23"
                                style="margin-left: -3px; margin-right: 14px" />
                            <span class="nav-text">Documentos </span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 42%; font-weight: 100; margin-right: -15px"
                                :icon="!menuDocumentsOpened ? ['fa', 'plus'] : ['fa', 'minus']" />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuDocuments">
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(21) > -1">
                                <router-link to="/admin/fds-list" class="mm-active">FDS
                                </router-link>
                            </li>
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(22) > -1">
                                <router-link to="/admin/fde-list" class="mm-active">Ficha de Emergência
                                </router-link>
                            </li>
                            <li class="mm-active" v-if="$store.getters.user.access.indexOf(29) > -1">
                                <router-link to="/admin/winner-docs" class="mm-active">Certidões
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li v-if="$store.getters.user.access.indexOf(23) > -1" :class="{
                'active-menu mm-active':
                    $route.path == '/admin/user-list' ||
                    $route.path == '/admin/timelogs' ||
                    $route.path == '/admin/customer-list' ||
                    $route.path == '/admin/logaccess' ||
                    $route.path == '/admin/configs' ||
                    $route.path == '/admin/reports',
            }">
                        <a class="clickable ai-icon" aria-expanded="false" @click="openMenuAdministrativo">
                            <i class="flaticon-381-settings-4"></i>
                            <span class="nav-text">Administrativo</span>
                            <font-awesome-icon class="menu-icon"
                                style="margin-left: 37%; font-weight: 100; margin-right: -15px" :icon="!menuAdministrativoOpened ? ['fa', 'plus'] : ['fa', 'minus']
                " />
                        </a>
                        <ul aria-expanded="false" class="left mm-collapse" id="menuAdministrativo">
                            <li v-if="$store.getters.user.access.indexOf(24) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/user-list',
            }">
                                <router-link to="/admin/user-list" class="mm-active">
                                    Usuários
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(25) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/timelogs',
            }">
                                <router-link to="/admin/timelogs" class="mm-active">
                                    Tempo de Logs
                                </router-link>
                            </li>
                            <!--                            <li-->
                            <!--                                v-if="-->
                            <!--                                  $store.getters.user.type_id == 4 ||-->
                            <!--                                  $store.getters.user.type_id == 1-->
                            <!--                                "-->
                            <!--                                :class="{-->
                            <!--                                  'active-menu mm-active':-->
                            <!--                                    $route.path == '/admin/customer-list',-->
                            <!--                                }"-->
                            <!--                            >-->
                            <!--                                <router-link to="/admin/customer-list" class="mm-active">-->
                            <!--                                    Clientes-->
                            <!--                                </router-link>-->
                            <!--                            </li>-->

                            <li v-if="$store.getters.user.access.indexOf(26) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/logaccess',
            }">
                                <router-link to="/admin/logaccess" class="mm-active">
                                    Log de Acesso
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(27) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/configs',
            }">
                                <router-link to="/admin/configs" class="mm-active">
                                    Configurações
                                </router-link>
                            </li>
                            <li v-if="$store.getters.user.access.indexOf(28) > -1" :class="{
                'active-menu mm-active': $route.path == '/admin/reports',
            }">
                                <router-link to="/admin/reports" class="mm-active">
                                    Relatórios
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li v-else :class="{
                'active-menu mm-active': $route.path == '/admin/profile',
            }">
                        <router-link to="/admin/profile" class="ai-icon">
                            <i class="flaticon-381-user"></i>
                            <span class="nav-text">Meu Perfil </span>
                        </router-link>
                    </li>
                    <!-- <li
                      v-if="$store.getters.user.access_requests == 1"
                      :class="{
                        'active-menu mm-active': $route.path == '/admin/documents-list',
                      }"
                    >
                      <a
                        class="clickable ai-icon"
                        aria-expanded="false"
                        @click="openMenuDocuments"
                      >
                        <i class="flaticon-044-file"></i>
                        <span class="nav-text">Documentos </span>
                        <font-awesome-icon
                          class="menu-icon"
                          style="margin-left: 42%; font-weight: 100; margin-right: -15px"
                          :icon="['fa', 'plus']"
                        />
                      </a>
                      <ul aria-expanded="false" class="left mm-collapse" id="menuDocuments">
                        <li class="mm-active">
                          <router-link to="/admin/documents-list" class="mm-active"
                            >FISPQ</router-link
                          >
                        </li>
                      </ul>
                    </li> -->
                </ul>
                <div class="copyright">
                    <p>
                        <strong>Grupo BAUMINAS</strong><br />
                        © 2023 Todos os direitos reservados
                    </p>
                    <p>
                        Desenvolvido por <a href="https://innovareti.com.br" target="_blank">InnovareTI</a>

                    </p>
                </div>
            </div>
        </div>

        <div class="content-body" style="min-height: 780px">
            <router-view v-if="!loading"></router-view>
            <div v-else>
                <div class="card-body text-center">
                    <span class="fa fa-spinner fa-spin fa-5x"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Breadcrumb from './Breadcrumb.vue'
import HeaderBar from "./Header.vue";
import env from "../../env.js";

export default {
    data() {
        return {
            loading: true,
            showAlert: false,
            menuFinanceiroOpened: false,
            menuCotacaoOpened: false,
            menuDocumentsOpened: false,
            menuPedidosOpened: false,
            menuAdministrativoOpened: false,
            menuDeliveryOpened: false,
            footerName: "Grupo BAUMINAS",
            dotEnv: {
                isAmbientaly: false,
            },
        };
    },
    computed: {
        showMenu() {
            const self = this;
            if (self.$store.state.maintenance) {
                if (self.$store.getters.user.type_id == 1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },
    },
    methods: {
        activateSpinner(){
            this.loading = true
        },
        handleAlertOk() {
            this.showAlert = false;

        },
        alertMessage() {
            this.showAlert = true;
        },
        controlMenu() {
            var element = document.getElementById("mainAPPDiv");
            var estilo = element.getAttribute("data-sidebar-style");

            if (estilo == "full") {
                element.setAttribute("data-sidebar-style", "mini");
            } else {
                element.setAttribute("data-sidebar-style", "full");
            }
        },
        logout: function () {
            this.$swal
                .fire({
                    title: "Tem certeza que deseja sair?",
                    text: "Esta operação não poderá ser desfeita.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push("/login");
                        this.$store.dispatch("logout");
                    }
                });
        },
        openMenu() {
            this.menuCotacaoOpened = false;
            this.menuDocumentsOpened = false;
            this.menuPedidosOpened = false;
            this.menuAdministrativoOpened = false;
            this.menuDeliveryOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuFinanceiroOpened =
                this.menuFinanceiroOpened == true ? false : true;
            if (this.menuFinanceiroOpened) {
                $("#menuFinanceiro").addClass("mm-show");
            } else {
                $("#menuFinanceiro").removeClass("mm-show");
            }
        },
        openMenuCotacao() {
            this.menuFinanceiroOpened = false;
            this.menuDocumentsOpened = false;
            this.menuPedidosOpened = false;
            this.menuAdministrativoOpened = false;
            this.menuDeliveryOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuCotacaoOpened = this.menuCotacaoOpened == true ? false : true;
            if (this.menuCotacaoOpened) {
                $("#menuCotacoes").addClass("mm-show");
            } else {
                $("#menuCotacoes").removeClass("mm-show");
            }
        },
        openMenuDelivery() {
            this.menuFinanceiroOpened = false;
            this.menuCotacaoOpened = false;
            this.menuDocumentsOpened = false;
            this.menuPedidosOpened = false;
            this.menuAdministrativoOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuDeliveryOpened = this.menuDeliveryOpened == true ? false : true;
            if (this.menuDeliveryOpened) {
                $("#menuDelivery").addClass("mm-show");
            } else {
                $("#menuDelivery").removeClass("mm-show");
            }
        },
        openMenuOrder() {
            this.menuFinanceiroOpened = false;
            this.menuCotacaoOpened = false;
            this.menuDocumentsOpened = false;
            this.menuAdministrativoOpened = false;
            this.menuDeliveryOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuPedidosOpened = this.menuPedidosOpened == true ? false : true;
            if (this.menuPedidosOpened) {
                $("#menuPedidos").addClass("mm-show");
            } else {
                $("#menuPedidos").removeClass("mm-show");
            }
        },
        openMenuDocuments() {
            this.menuFinanceiroOpened = false;
            this.menuCotacaoOpened = false;
            this.menuPedidosOpened = false;
            this.menuAdministrativoOpened = false;
            this.menuDeliveryOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuDocumentsOpened =
                this.menuDocumentsOpened == true ? false : true;
            if (this.menuDocumentsOpened) {
                $("#menuDocuments").addClass("mm-show");
            } else {
                $("#menuDocuments").removeClass("mm-show");
            }
        },
        openMenuAdministrativo() {
            this.menuFinanceiroOpened = false;
            this.menuCotacaoOpened = false;
            this.menuDocumentsOpened = false;
            this.menuPedidosOpened = false;
            this.menuDeliveryOpened = false;
            $(".mm-collapse").removeClass("mm-show");
            this.menuAdministrativoOpened =
                this.menuAdministrativoOpened == true ? false : true;
            if (this.menuAdministrativoOpened) {
                $("#menuAdministrativo").addClass("mm-show");
            } else {
                $("#menuAdministrativo").removeClass("mm-show");
            }
        },
    },
    created() {
        this.footerName = env.footerName;
        this.dotEnv.isAmbientaly = env.isAmbientaly;
    },
    mounted() {
        this.loading = false
    },
    components: {
        HeaderBar,
    },
};
</script>

<style>

body{
    height: 100vh;
    margin: 0;
    padding: 0;
   
    background-image: unset; /* Caminho da sua imagem */
    background-size: unset; /* Cobre todo o fundo */
    background-position: unset; /* Centraliza a imagem */
    background-repeat: unset; /* Evita repetição da imagem */
    display: block;
    justify-content: center;
    align-items: center;
}

[data-sidebar-style="full"] .metismenu>li.active-menu.mm-active {
    width: 90% !important;
}

.deznav-scroll {
    overflow-y: scroll;
    /* Adiciona uma barra de rolagem somente quando necessário */
    scrollbar-width: none;
    /* Define a largura da barra de rolagem como fina (não é suportado no Safari) */
    scrollbar-color: #888888 #f0f0f0;
    /* Define as cores da barra de rolagem (não é suportado no Safari) */
}

.deznav-scroll::-webkit-scrollbar {
    width: 1px;
    /* Largura da barra de rolagem */
}

.mm-active:hover {
    cursor: pointer;
}
</style>
<style scoped>
.card {
    position: absolute;
    margin: 50% 300%;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
}

.custom-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.custom-alert-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-alert-content h2 {
    margin-top: 0;
}

.custom-alert-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.custom-alert-content button:hover {
    background-color: #0056b3;
}
</style>
