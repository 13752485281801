<template>
  <div>
    <div class="row" style="overflow: hidden">


      <div class="col-lg-12">
        <div class="card">
        
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1"
                      type="button" role="tab" aria-controls="tab1" aria-selected="true">Entregas Atrasadas</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="tab2-tab" data-bs-toggle="tab" data-bs-target="#tab2" type="button"
                      role="tab" aria-controls="tab2" aria-selected="false">Relatórios Agendados</button>
                  </li>
                </ul>
                <!-- Tab content -->
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    <p class="mt-3">O botão abaixo irá gerar o relatório de entregas atrasadas</p>
                    <button class="btn btn-primary" @click="lateDelivery">Gerar</button>

                  </div>
                  <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">

                    <div class="row">
                      <div class="col-md-12">

                      
                        <button class="btn btn-success white float-right mt-3" @click="openModalNovo">
                          Novo Relatorio
                        </button>
                      </div>
                      
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-12">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Título</th>
                              <th scope="col">Descrição</th>
                              <th scope="col">Qtd Downloads</th>
                              <th scope="col">Ações</th>
                            </tr>
                          </thead>
                          <tbody v-if="reports.length > 0">
                            <tr v-for="report in reports" :key="report.id">
                              <td>{{ report.id }}</td>
                              <td>{{ report.title }}</td>
                              <td>{{ report.description }}</td>
                              <td>{{ report.downloads }}</td>
                              <td align="center">
                                <a :href="$store.state.api + 'reports/download/' + report.id
                                  " target="_blank" class="btn btn-primary">Baixar</a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" align="center">
                                Nenhum relatório disponível.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- Modal Produto -->

    <div id="modalNovo">
      <div class="modal-fade-background"></div>
      <div :class="['modal fade show']" id="modal-pending" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div id="ModalNovo">
              <div class="row">
                <div class="col-md-6 bold">Gerar novo relatório</div>
                <div class="col-md-6">
                  <button @click="closeModalNovo()" style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    " class="btn btn-danger float-right">
                    X
                  </button>
                </div>
              </div>
              <div class="row mt-4">

                <div class="col-md-12 mb-3">
                  <label>Escolha o tipo do relatório</label>
                  <select class="form-control" v-model="generate.type">
                    <option value="1">Pedidos</option>
                    <option value="2">Cotação</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Data Inicial</label>
                  <input type="date" class="form-control" v-model="generate.date_start" />
                </div>
                <div class="col-md-6">
                  <label>Data Final</label>
                  <input type="date" class="form-control" v-model="generate.date_end" />
                </div>


                <div class="col-md-12 mt-3">
                  <button class="btn btn-success white float-right" @click="generateReport">
                    Gerar Relatório
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
});
export default {
  data() {
    return {
      reports: [],
      generate: {},
    };
  },
  computed: {},
  components: {},
  methods: {
    lateDelivery(){
      let api =
        this.$store.getters.api +"delivery/export-late";

      let self = this

      this.loadingReport = true

      axios
        .get(api)
        .then((response) => {

          this.$toast.success(
              "Sucesso, enviamos um email para você com o relatório. Por favor, confira a sua caixa de entrada! "
          );

        })
        .catch((error) => {
          // this.$toast.error(
          //   "Erro na solicitação de Contratos:" + error.response.data.message
          // );
          self.loadingReport = false
        });
    },
    openModalNovo() {
      show_modal("modalNovo");
    },
    closeModalNovo() {
      close_modal("modalNovo");
    },
    generateReport() {
      const self = this;
      const api = this.$store.state.api + "reports/generate";

      axios
        .post(api, self.generate)
        .then((response) => {
          self.getReports();
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    getReports() {
      const self = this;
      const api = this.$store.state.api + "reports/?orderByDesc=id";

      axios
        .get(api)
        .then((response) => {
          self.reports = response.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>
<style scoped>
.card {
  margin: 45px;
}

#ModalNovo {
  padding: 15px;
}
</style>